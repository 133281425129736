import * as React from 'react';
import styled from 'styled-components';

import { smallTabletMQ } from 'styles/breakpoints';

const ProgressCont = styled.div`
  border-radius: .5rem;
  width: 100%;
  position: absolute;
  height: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 400px;
  ${smallTabletMQ('max-width: 300px;')}
`;

const Bar = styled.div<{ width: number }>`
  background-color: ${({ theme }) => theme.colors.yellow[1]};
  height: 10px;
  border-radius: 1rem;
  width: ${({ width }) => width}%;
  transition: 1s ease;
  transition-delay: 0.5s;
`;


export const ProgressBar: React.FC<IProgressBarProps> = ({ seconds }) => {

  const [width, setWidth] = React.useState(0);


  React.useEffect(() => {
    const interval = setInterval(() => {
      if (width < seconds) {
        setWidth(width + 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });


  return (
    <ProgressCont >
      <Bar width={width / seconds * 100}/>
    </ProgressCont>
  );
};
