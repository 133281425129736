import wireBuddyApi from './index';

export default {
  async get(data: IURLGet): Promise<{ url: string }> {
    try {
      const res = await wireBuddyApi.get(
        `marketingSite/url?businessName=${data.businessName}`
      ) as unknown as { data: { url: string } };

      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
