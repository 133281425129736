import React from 'react';
import styled from 'styled-components';

import { ErrorLabel, FormInput } from 'components/FormInput';
import { largeMobileMQ, smallTabletMQ } from 'styles/breakpoints';
import { config } from 'constants/config';

const Container = styled.div`
  width: 520px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 10px;
`;

const ErrorMessage = styled(ErrorLabel)`
  margin-top: 10px;
`;

const PassMessage = styled(ErrorMessage)`
  color: ${({ theme }) => theme.colors.green[0]} !important;
`;

const URLContainer = styled.div`
  font-family: monospace;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey[5]};
  border-radius: 3px;
  -moz-appearance: textfield;
  box-sizing: border-box;
  height: 39px;
  outline: none;
  padding: 12px 5px;
  transition: border .5s ease-in-out;
  transition: border-color .5s ease-in-out;
  transition: opacity .5s ease-in-out;
  width: 100%;
  display: flex;
  align-items: center;
  ${smallTabletMQ(`
    font-size: 12px;
  `)}
  word-break: break-word;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey[0]};
`;

const Https = styled.span`
  min-width: 70px;
  ${largeMobileMQ(`
    min-width: 60px;
  `)}
`;
const WirebuddyDotCa = styled.span`
  min-width: 111px;
  ${largeMobileMQ(`
    min-width: 95px;
  `)}
`;

const BoldInput = styled(FormInput)<{ value: string }>`
  font-weight: bold;
  width: ${({ value }) => value.length === 0 ? 13 : value.length + 1}ch;
  ${({ value }) => smallTabletMQ(`
    width: ${value.length / config.maxBusinessNameLength * 60}%;
  `)};
  ${smallTabletMQ(`
    min-width: 76px;
  `)};
  ${largeMobileMQ(`
    width: 100%;
  `)}
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
  input {
    border: 1px solid transparent;
    text-overflow: ellipsis;
    padding: 9px 0 !important;
    :focus {
      border: 0.5px solid ${({ theme }) => theme.colors.yellow[0]};
    }
    margin: 0;
    ::placeholder {
      color: ${({ theme }) => theme.colors.grey[5]};
    }
    word-break: break-word;
    font-size: 14px;
    ${smallTabletMQ(`
      font-size: 12px;
    `)}
  }
`;

export const URLVisualizer: React.FC<IURLVisualizerProps> = ({
  url, errorText, onChange, isDisabled,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Container>
      <URLContainer onClick={() => { inputRef?.current?.focus(); }}>
        <>
          <Https>https://</Https>
          <BoldInput
            inputRef={inputRef}
            name="url"
            isDisabled={isDisabled}
            spellCheck={false}
            placeholder="business-name"
            handleChange={onChange}
            value={url}
          />
          <WirebuddyDotCa>.wirebuddy.ca</WirebuddyDotCa>
        </>
      </URLContainer>
      {!errorText ? (
        <PassMessage>This URL is available</PassMessage>
      ) : (
        <ErrorMessage>{errorText}</ErrorMessage>
      )}
    </Container>
  );
};
