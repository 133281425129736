import * as React from 'react';
import styled from 'styled-components';

import { largeMobileMQ, smallTabletMQ } from 'styles/breakpoints';
import { Container, Blue } from 'components/Containers';
import { Seo } from 'components/Seo';
import { CenteredWhiteHeader } from 'components/Text';
import { NavBar } from 'components/NavBar';
import { Footer } from 'components/Footer';

import { SignupForm } from 'forms/Signup';

const StartFreeTrialHeader = styled(CenteredWhiteHeader)`
  margin-bottom: 15px;
`;

const ColContainer = styled(Container)`
  flex-direction: column;
  ${smallTabletMQ(`
    padding: 25px 50px 60px 50px;
  `)}
  ${largeMobileMQ('padding: 18px 15px 60px 15px;')}
  padding: 35px 50px 60px 50px;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  width: 100%;
  ${smallTabletMQ('padding-top: 5px;')}
  flex: 1;
`;

const BlueFlex = styled(Blue)`
  flex: 1 1 100%;
  background-image: radial-gradient(
    circle at 50% 70%,
    rgba(243, 244, 246, 0.08) 15%,
    transparent
  );
`;

const SignUp = () => (
  <>
    <NavBar />
    <Seo
      title="WireBuddy - Sign Up"
      description="Try WireBuddy for free"
      lang="en"
      image="src/assets/svgs/WireBuddyLogoPartial.svg"
    />
    <BlueFlex>
      <ColContainer>
        <StartFreeTrialHeader>
          Start your free trial
        </StartFreeTrialHeader>

        <ListContainer>
          <SignupForm />
        </ListContainer>

      </ColContainer>
    </BlueFlex>
    <Footer />
  </>
);

export default SignUp;
