/* eslint-disable camelcase */
import * as React from 'react';
import styled, { css } from 'styled-components';

import signupApi from 'api/signup';
import urlApi from 'api/url';

import { Button, OrangeTryItButton } from 'components/Button';
import { ProgressBar } from 'components/ProgressBar';
import { FormInput, InputLabel } from 'components/FormInput';
import { WhiteDetails } from 'components/Text';
import { Modal } from 'components/Modal';
import { URLVisualizer } from 'components/URLVisualizer';
import { Icon } from 'components/Icon';

import LoadingGearDoubleWhite from 'assets/svgs/LoadingGearDoubleWhite.svg';
import { largeMobileMQ, smallTabletMQ } from 'styles/breakpoints';
import { config } from 'constants/config';
import { validateEmail, errorMessage, validateName } from 'utils/validate';
import { chevronLeft } from 'assets/icons';

const InputContainer = styled.div`
  padding-top: 7px;
  padding-bottom: 7px;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 1em;
`;

const LoadingGear = styled(LoadingGearDoubleWhite)`
  width: 20px;
  height: 20px;
  position: absolute;
`;
const StyledInput = styled(FormInput)`
  width: 500px;
  height: 60px;
  ${InputLabel} {
    color: ${({ theme }) => theme.colors.black};
  }
  ${smallTabletMQ('width: 100%;')}
`;

const CenterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 24px;
  width: 100%;
  flex: 4;
  align-items: center;
`;

const BackIcon = styled(Icon)`
  position: absolute;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 22px;
  width: 100%;

  ${smallTabletMQ(css`
    margin-bottom: 10px;
  `)}
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 500px;

  ${smallTabletMQ(css`
    width: 100%;
  `)}
`;

const InputFields = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;

  ${smallTabletMQ(css`
    width: 100%;
  `)}
`;

const Text = styled(WhiteDetails)`
  width: 100%;
  padding-top: 0;
  color: ${({ theme }) => theme.colors.grey[9]};
  font-weight: 300;
`;

const MarginText = styled(Text)`
  margin-bottom: 15px;
`;

const Subtitle = styled.h2`
  width: 100%;
  margin-bottom: 10px;
`;

const Circle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0 5px;
  background-color: ${({ theme }) => theme.colors.grey[5]};

  ${smallTabletMQ(css`
    width: 10px;
    height: 10px;
  `)}
`;

const OrangeCircle = styled(Circle)`
  background-color: ${({ theme }) => theme.colors.yellow[0]};
`;

const MarginSubmitButton = styled(OrangeTryItButton)`
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${smallTabletMQ(css`
    width: 100%;
    margin-top: 15px;
  `)}
`;

const BlurredContainer = styled.div<{ blurred: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  padding: 30px;
  ${({ blurred }) => blurred && `
    opacity: 0.8;
    filter: blur(4px);
  `}
  ${smallTabletMQ('padding: 15px 20px;')}
  ${largeMobileMQ('padding: 15px 12px;')}
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SignupForm: React.FC = () => {
  const INITIAL_STATE: ISignupFormState = {
    formErrors: {},
    formTouched: {},
    isValid: false,
    signup: {
      businessName: '',
      email: '',
      password: '',
      phone: '',
    },
  };

  const defaultURLError = 'Please enter a valid URL';
  const defaultSignupError = 'Signup has failed, please refresh and try again.';

  const [formState, setFormState] = React.useState<ISignupFormState>(INITIAL_STATE);
  const [url, setUrl] = React.useState<string>('');
  const [urlError, setUrlError] = React.useState<string | undefined>(undefined);
  const [stage, setStage] = React.useState<1 | 2>(1);

  const [isURLGetLoading, setIsURLGetLoading] = React.useState(false);
  const [isSignupPostLoading, setIsSignupPostLoading] = React.useState(false);
  // Modals
  const [isFailModalOpen, setIsFailModalOpen] = React.useState(false);
  const [modalFailureMessage, setModalFailureMessage] = React.useState(defaultSignupError);

  React.useEffect(() => {
    let script = document.createElement('script');
    script.src =
      `https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_KEY}`;
    document.body.appendChild(script);

    script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${
      process.env.GATSBY_GOOGLE_TAG_ADS_CONVERSION_ID
    }`;
    document.body.appendChild(script);

    script = document.createElement('script');
    script.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${process.env.GATSBY_GOOGLE_TAG_ADS_CONVERSION_ID}');
    `;
    document.body.appendChild(script);
  }, []);

  const handleChangeURL = (newURL: string) => {
    newURL = newURL.split(' ')
    .join('-')
    .replace(/[^0-9a-zA-Z-\s]/gi, '')
    .toLowerCase();

    // Prevent URLs from being more than max, but the +1
    // allows the user to see the error
    // and understand that extra characters are over the max
    if (newURL.length > config.maxBusinessNameLength + 1) {
      return;
    }

    setUrl(newURL);

    signupApi.get({ url: newURL }).then(
      () => {
        setUrlError(undefined);
      },
      (e: IErrorResponse) => {
        setUrlError(errorMessage(e, defaultURLError));
      }
    );
  };

  const handleChangeValue = (key: keyof ISignupForm, value?: string) => {
    setFormState({
      ...formState,
      formTouched: {
        ...formState.formTouched,
        [key]: true,
      },
      signup: {
        ...formState.signup,
        [key]: value,
      },
    });
  };

  React.useEffect(() => {
    handleValidate();
  }, [formState.signup]);

  const handleValidate = (touch?: boolean) => {
    const emailCheck = validateEmail(formState.signup.email);
    let passwordCheck = !formState.signup?.password ? 'Please enter a password' : undefined;
    passwordCheck = formState.signup?.password.length < config.minPassLength
      ? `Your password must be at least ${config.minPassLength} characters` : undefined;
    const businessNameCheck = validateName(
      formState.signup.businessName,
      'business name',
      undefined,
      undefined,
      3
    );
    const phoneCheck = formState.signup?.phone?.length === 10;

    const formErrors: ISignupFormError = {
      businessName: businessNameCheck.valid ? undefined : businessNameCheck.reason,
      email: !emailCheck.valid ? emailCheck.reason : undefined,
      password: passwordCheck,
      phone: !phoneCheck ? 'Please enter a valid phone number' : undefined,
    };

    const isValid = Object.values(formErrors)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      .reduce((acc, error) => (acc && !error), true) as boolean;
    setFormState({
      ...formState,
      formErrors,
      formTouched: {
        businessName: touch ? true : formState.formTouched.businessName,
        email: touch ? true : formState.formTouched.email,
        password: touch ? true : formState.formTouched.password,
        phone: touch ? true : formState.formTouched.phone,
      },
      isValid,
    });
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsSignupPostLoading(false);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  });


const gtag_report_conversion = () => {
  // // eslint-disable-next-line
  if (typeof window !== 'undefined' && window.hasOwnProperty('gtag')) {
    // @ts-expect-error
    // eslint-disable-next-line
    window.gtag('event', 'conversion', {
      send_to: process.env.GATSBY_GOOGLE_TAG_ADS_AW_ID,
    });
  }
};

  const handleSubmit = () => {
    if (!urlError && !isSignupPostLoading) {
      setIsSignupPostLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.grecaptcha.ready(() => {
        // eslint-disable-next-line
        window.grecaptcha.execute(process.env.RECAPTCHA_KEY, { action: 'signup' })
          .then((token?: string) => {
            submitHelper(token);
          }, () => {
            submitHelper(undefined);
          });
      });
    }
  };

  const submitHelper = (token?: string) => {
    setIsFailModalOpen(false);
    signupApi.post({
      ...formState.signup,
      url,
      recaptchaToken: token,
    }).then(() => {
      setIsSignupPostLoading(false);
      setIsFailModalOpen(false);
      setFormState(INITIAL_STATE);
      window.location.href = `https://${url}.` +
      `wirebuddy.ca/login?email=${formState.signup.email}`;
    }, (e: IErrorResponse) => {
      setIsFailModalOpen(true);
      setIsSignupPostLoading(false);
      setModalFailureMessage(errorMessage(e, defaultSignupError));
    });
    gtag_report_conversion();
  };

  const stageOne = () => (
    <>
      <InputFields>
        <Row>
          <CenterRow>
            <OrangeCircle />
            <Circle />
          </CenterRow>
        </Row>
        <Subtitle>Create an Account</Subtitle>
        <Text>
          Sign up to unlock all of our features to start growing your business. <br />
          No credit card required.
        </Text>
        <InputContainer>
          <StyledInput
            name="email"
            label="Email"
            placeholder="john.smith@wirebuddy.ca"
            isRequired
            isDisabled={isURLGetLoading}
            error={formState.formErrors.email}
            touched={formState.formTouched.email}
            value={formState.signup.email}
            handleChange={(value) => {
              handleChangeValue('email', value);
            }}
          />
        </InputContainer>
        <InputContainer>
          <StyledInput
            name="phone"
            label="Phone Number"
            placeholder="(416) 555-1234"
            maskType="phone"
            isRequired
            isDisabled={isURLGetLoading}
            error={formState.formErrors.phone}
            touched={formState.formTouched.phone}
            value={formState.signup.phone}
            handleChange={(value) => {
              handleChangeValue('phone', value);
            }}
          />
        </InputContainer>
        <InputContainer>
          <StyledInput
            name="password"
            label="Enter a password"
            placeholder="Enter a password"
            isRequired
            type="password"
            isDisabled={isURLGetLoading}
            error={formState.formErrors.password}
            touched={formState.formTouched.password}
            value={formState.signup.password}
            handleChange={(value) => {
              handleChangeValue('password', value);
            }}
          />
        </InputContainer>
        <InputContainer>
          <StyledInput
            name="businessName"
            label="Business Name"
            placeholder="E.g. John's Contracting Services"
            isRequired
            isDisabled={isURLGetLoading}
            error={formState.formErrors.businessName}
            touched={formState.formTouched.businessName}
            value={formState.signup.businessName}
            handleChange={(value) => {
              handleChangeValue('businessName', value);
            }}
          />
        </InputContainer>
      </InputFields>
      <MarginSubmitButton isDisabled={!formState.isValid || isURLGetLoading}
        onClick={() => {
          const businessName = formState.signup.businessName;
          if (businessName) {
            setIsURLGetLoading(true);
            urlApi.get({ businessName }).then((data) => {
              setIsURLGetLoading(false);
              setStage(2);
              handleChangeURL(data?.url ?? businessName);
            }, () => {
              setStage(2);
              setIsURLGetLoading(false);
              handleChangeURL(businessName);
            });
          }
         }}
      >
        {isURLGetLoading ? <LoadingGear /> : 'Continue'}
      </MarginSubmitButton>
    </>
  );

  const stageTwo = () => (
    <>
      <InputFields>
        <Row>
          <BackIcon
            path={chevronLeft}
            onClick={() => {
              setStage(1);
            }}
          />
          <CenterRow>
            <OrangeCircle />
            <OrangeCircle />
          </CenterRow>
        </Row>
        <Subtitle>Your Suggested WireBuddy URL</Subtitle>
        <MarginText>
          Your WireBuddy URL is how you will access your WireBuddy account. <br />
          We generated a this URL for you using your company name.
        </MarginText>
        <InputContainer>
          <URLVisualizer
            url={url}
            onChange={handleChangeURL}
            errorText={urlError}
            isDisabled={isSignupPostLoading}
          />
        </InputContainer>
        <p>
          You can modify your URL above to adjust it to your preference.
          It can only include numbers letters and hyphens.
        </p>
      </InputFields>
      <MarginSubmitButton isDisabled={!!urlError || !formState.isValid || isSignupPostLoading}
        onClick={handleSubmit}
      >
        Sign Up
      </MarginSubmitButton>
    </>
  );

  return (
    <StyledContainer>
      <Modal
        isOpen={isFailModalOpen}
        handleCloseModal={() => { setIsFailModalOpen(false); }}
        title="Sorry, about that! Something went wrong"
        content={<>
          <p>{modalFailureMessage}</p>
          <Button onClick={() => {
            window.location.reload();
          }}>Refresh and Try again</Button>
        </>}
      />
      <BlurredContainer blurred={isSignupPostLoading}>
        <Form
          autoComplete="off"
          encType="application/json"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {stage === 1 ? stageOne() : stageTwo()}
        </Form>
      </BlurredContainer>
      {isSignupPostLoading && <ProgressBar seconds={5}/>}
      <div
        className="g-recaptcha"
        data-sitekey={process.env.GATSBY_RECAPTCHA_KEY}
        data-size="invisible"
      ></div>
    </StyledContainer>
  );
};
